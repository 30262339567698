// import React, { Component } from "react";
// import Widecard from "../components/Widecard";
// class Jobs extends Component {
//   render() {
//     return (
//       <div className="condiv">
//         <h1 className="subtopic">Werkervaring</h1>
//         <Widecard
//           title="Python Developer"
//           where="Bencom Group"
//           from="September 2020"
//           to="Nu"
//         />
//         <Widecard
//           title="Developer"
//           where="Piercing Mania"
//           from="2018"
//           to="2020"
//         />
//       </div>
//     );
//   }
// }
// export default Jobs;

import React from "react";
import { AnimateSharedLayout } from "framer-motion";
import Item from "../components/Item";
import { useNav } from "../customHooks/useNav";
import "./Page.css";
import "../styles.css";
import piercingmania from "../img/piercingmania.webp";
import bencomgroup from "../img/bencom.webp";
import tno from "../img/tno.webp";

// This is an example of animating shared layouts
// using react and framer-motion library.
const itemsList = [
  {
    index: 0,
    photo: tno,
    header: {
      where: `TNO`,
      from: 2022,
      to: "",
      title: "Scientist Innovator",
      employmentType: "fulltime",
    },
    content: <p> Scientist Innovator op de afdeling Data Ecosystemen binnen TNO. Voornamelijk bezig met ontwikkelingen rondom IDS (International Data Spaces).</p>,
  },
  {
    index: 1,
    photo: bencomgroup,
    header: {
      where: `Bencom Group`,
      from: 2020,
      to: 2022,
      title: "Backend Developer",
      employmentType: "fulltime",
    },
    content: <p>Voornamelijk werkzaam voor de backend van de Bencompare app. Zowel het toevoegen van nieuwe functionaliteiten als werkzaamheden om de app optimaal te laten runnen. Gebruikte technieken:
            <ul>
            <li>Microsoft Azure</li>
            <li>GraphQL</li>
            <li>Django</li>
            <li>PostgreSQL</li>
            <li>Celery</li>
            <li>Docker</li>
            </ul></p>,
  },
  {
    index: 2,
    photo: piercingmania,
    header: {
      where: `Piercing Mania`,
      from: 2018,
      to: 2020,
      title: "Backend Developer",
      employmentType: "parttime",
    },
    content: <ul>
      <li>Ervaring opgedaan met het maken van een dashboard met behulp van Django. In dit dashboard waren sales data en voorraden te zien per product en per categorie.</li>
      <li>Gewerkt aan een manier om automatisch producten toe te kunnen voegen aan onze website. Hiervoor hebben we AirFlow, Celery en Django gecombineerd. Eerst werd data opgehaald met behulp van een zelf geschreven scraper, later kregen we een API koppeling.</li>
      <li>Gewerkt aan een applicatie in het framework Odoo. Odoo is een open-source ERP pakket en het doel van de applicatie was om de bestaande applicatie te koppelen aan de werkwijze van het bedrijf.</li>
      <li>Meegedacht over IT oplossingen op managementniveau.</li>
      </ul>,
  },
];

const Jobs = () => {
  const jobsRef = useNav("Werkervaring");

  return (
    <section ref={jobsRef} id="jobsContainer" className="even">
      <div className="condiv">
      <h1 className="subtopic">Mijn werkervaring</h1> 
      <div className="condivlist">
        <AnimateSharedLayout>
          {/* Mapping through itemList array to render layouts*/}
          {itemsList.map((item) => (
            <Item
              key={item.index}
              header={item.header}
              photo={item.photo}
              content={item.content}
            />
          ))}
        </AnimateSharedLayout>
      </div>
      </div>
    </section>
  );
};

export default Jobs;
