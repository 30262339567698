
import React, { useContext } from 'react';
import { NavContext } from '../context/NavContext';

const NavLink = ({ navLinkId, scrollToId, setClick }) => {
	const { activeNavLinkId, setActiveNavLinkId } = useContext(NavContext);
	const handleClick = () => {
		setActiveNavLinkId(navLinkId);
		document.getElementById(scrollToId).scrollIntoView({ behavior: 'smooth' });
		setClick(false);
	};

	return (
		<li
			id={navLinkId}
			className={activeNavLinkId === navLinkId ? 'nav-item active' : 'nav-item'}
			onClick={handleClick}
		>
			{navLinkId}
		</li>
	);
};

export default NavLink;