import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";

class Social extends Component {
  render() {
    return (
      <div className="social" id={this.props.id}>
        <a href="https://github.com/Willeem" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faGithub} className="sociali" />
        </a>
        <a
          href="http://linkedin.com/in/willem-datema-58b24647/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} className="sociali" />
        </a>
      </div>
    );
  }
}
export default Social;
