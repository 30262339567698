import React from "react";
import { useNav } from "../customHooks/useNav";
import "./Page.css";
const About =() => {
  const aboutRef = useNav('Over mij');

  return (
    <section ref={aboutRef} id="aboutContainer" className="even">
      <div className="condiv">
        <h1 className="subtopic">Over mij</h1>
        <h1> Mijn naam is Willem Datema</h1>
        <h4>
          Afgestudeerd in <u>Information Science</u> | Momenteel werkzaam als{" "}
          <u>Scientist Innovator</u>
        </h4>
        <br></br>
        <p>
          Ik ben iemand die graag dingen zelf uitzoekt en goed zelfstandig kan
          werken. Mensen omschrijven mij als rustig, betrouwbaar, ambitieus en
          een harde werker.
          <br />
          Binnen de IT heb ik verschillende interesses en ook ervaring met
          verschillende takken van sport. Zo heb ik me tijdens mijn studie
          voornamelijk op Machine Learning gefocust, terwijl ik mijn
          werkervaring hoofdzakelijk heb opgedaan in Backend Development. Hierin
          ben ik gespecialiseerd in de taal <b>Python</b> en in het bijzonder
          het framework <b>Django</b>. In mijn vrije tijd heb ik deze website
          gemaakt in <b>ReactJS</b>. Mijn hobby’s zijn hardlopen, wandelen in de
          natuur, (board)gamen en afspreken met vrienden.
        </p>
      </div>
    </section>
  );
}

export default About;
