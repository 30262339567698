import React from "react";
import { useNav } from "../customHooks/useNav";
import Social from "../components/Social";
import "./Page.css";

const Contact = () => {
  // useNav takes in a navLinkId and returns a ref
  // this ref is used to register the navLinkId that's
  // currently in view, and apply activeClass styling
  // to the corresponding nav childElement

  const contactRef = useNav("Contact");
  return (
    <section ref={contactRef} id="contactContainer">
      <div className="condiv">
          <p>Ik kom graag met je in contact! Voeg me toe op LinkedIn of bekijk mijn Github pagina.</p>
        <Social id="contact"/>
      </div>
    </section>
  );
};

export default Contact;
