import { Nav } from "./nav";
import { Main } from "./contents";
import NavProvider from "./context/NavContext";
import "./App.css";

function App() {
  return (
      <NavProvider>
        <Nav />
        <Main />
      </NavProvider>
  );
}

export default App;
