import React, { Component } from "react";
class Widecard extends Component {
  render() {
    return (
      <a href={this.props.url} target="_blank" rel="noreferrer">
        <div className="widecard">
          <img
            src={this.props.image}
            className="img-fluid-company-logo"
            alt="RUG"
          />
          <h3 className="headertext">{this.props.where}</h3>
          <h4 className="secondtext">
            {this.props.from} - {this.props.to}
          </h4>
          <h3 className="secondtext maintext">{this.props.title}</h3>
        </div>
      </a>
    );
  }
}
export default Widecard;
