import React from "react";
import { motion } from "framer-motion";
import { useNav } from "../customHooks/useNav";
import profilepic from "../img/profilepic.webp";
import Social from "../components/Social";
import "./Page.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity, faUserTie } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  // useNav takes in a navLinkId and returns a ref
  // this ref is used to register the navLinkId that's
  // currently in view, and apply activeClass styling
  // to the corresponding nav childElement

  const homeRef = useNav("Home");
  return (
    <section ref={homeRef} id="homeContainer" className="green home">
      <div className="condiv home">
        <img src={profilepic} className="profilepic" alt="profile"></img>
        <motion.h1 initial={{opacity: 0}} animate={{ opacity: 1}} transition={{ duration: 2 }}>
          {" "}
          Hi, mijn naam is Willem Datema,
        </motion.h1>
        <br />
        <div className="skills">
          <motion.div
            className="item"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 2 }}
          >
            <FontAwesomeIcon icon={faUserTie} /> Software Developer
          </motion.div>
          <motion.div
            className="item"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 2, duration: 2 }}
          >
            <FontAwesomeIcon icon={faCity} /> Groningen
          </motion.div>
        </div>
        <Social id="home"/>
      </div>
    </section>
  );
};

export default Home;
