import React from 'react';
import { Home, About, Jobs, Education, Skills, Contact } from './';

const Main = () => (
	<main>
		<Home />
		<About />
		<Skills />
		<Jobs />
		<Education />
		<Contact />
	</main>
);

export default Main;