import React, { useState } from "react";
import NavLink from "./NavLink";
import { navLinks } from "./NavLinks";
import "./Nav.css";
import logo from "../img/logo-pink.webp";
import { FaBars, FaTimes } from "react-icons/fa";

const Nav = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <div className="nav-bar">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        {navLinks.map(({ navLinkId, scrollToId }, idx) => (
          <NavLink
            key={idx}
            navLinkId={navLinkId}
            scrollToId={scrollToId}
            setClick={handleClick}
          />
        ))}
      </ul>
      <div className="hamburger" onClick={handleClick}>
        {click ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>
    </div>
  );
};

export default Nav;
