import React from "react";
import { useNav } from "../customHooks/useNav";
import "./Page.css";
import Widecard from "../components/Widecard";
import ruglogo from "../img/rug.webp";

const Education = () => {
  const educationRef = useNav("Opleidingen");

  return (
    <section ref={educationRef} id="educationContainer" className="green">
      <div className="condiv">
        <h1 className="subtopic">Mijn Opleidingen</h1>
        <Widecard
          title="Master Information Science"
          where="Rijksuniversiteit Groningen"
          from="2019"
          to="2020"
          image={ruglogo}
          url="https://www.rug.nl/masters/information-science/"
        />
        <Widecard
          title="Bachelor Informatiekunde"
          where="Rijksuniversiteit Groningen"
          from="2015"
          to="2019"
          image={ruglogo}
          url="https://www.rug.nl/bachelors/information-science/"
        />
      </div>
    </section>
  );
};
export default Education;
