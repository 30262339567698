import React from "react";
import { useNav } from "../customHooks/useNav";
import "./Page.css";
import "../img/font-mfizz-2.4.1/font-mfizz.css";

const Skills = () => {
  const skillsRef = useNav("Vaardigheden");
  return (
    <section ref={skillsRef} id="skillsContainer" className="blue">
      <div className="condiv">
        <h1 className="subtopic">Vaardigheden</h1>
        <p>
          Hieronder staan de vaardigheden die ik tijdens mijn opleidingen en
          tijdens mijn werk heb ontwikkeld.
        </p>
        <div className="skills">
          <div className="item">
            <i className="devicon devicon-python-plain"></i>
            Python
          </div>
          <div className="item">
          <i className="devicon devicon-django-plain"></i>
            Django
          </div>
          <div className="item">
          <i className="devicon devicon-graphql-plain"></i>
            GraphQL
          </div>
          <div className="item">
          <i className="devicon devicon-azure-plain"></i>
            Microsoft Azure
          </div>
          <div className="item">
          <i className="devicon devicon-mysql-plain"></i>
            MySQL
          </div>
          <div className="item">
          <i className="devicon devicon-postgresql-plain"></i>
            PostgreSQL
          </div>
          <div className="item">
          <i className="devicon devicon-git-plain"></i>
            Git
          </div>
          <div className="item">
          <i className="devicon devicon-docker-plain"></i>
            Docker
          </div>
          <div className="item">
          <i className="devicon devicon-linux-plain"></i>
            Linux
          </div>
          <div className="item">
          <i className="devicon devicon-kubernetes-plain"></i>
            Kubernetes
          </div>
          <div className="item">
          <i className="devicon devicon-html5-plain"></i>
            HTML
          </div>
          <div className="item">
          <i className="devicon devicon-css3-plain"></i>
            CSS
          </div>
        </div>
      </div>
    </section>
  );
};
export default Skills;
